/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
.brand__lockup .container a img {
  max-height: 84px; }

#primary-nav .level-1 {
  width: 16.6667%; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      padding: 8px 4px;
      height: 64px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .level-1 {
      height: auto; } }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.pane-node-field-section-header-image img {
  width: 100%; }

h2.cta_h2 {
  margin-bottom: 10px; }

.cta_img {
  float: left; }
  .cta_img img {
    margin: 0 10px 10px 0; }

.messages.error {
  display: none; }

.pane-seminar-events-panel-pane-3 h3, .pane-faculty-spotlight-panel-pane-2 h3 {
  margin-bottom: 20px; }

.pane-node-field-news-category {
  clear: both; }

.field-name-field-news-category a, .views-field-field-news-category a {
  color: #cf4520;
  font-size: 14px;
  background: url(../images/tag.png) 0 0 no-repeat;
  background-size: 16px 16px;
  padding-left: 25px;
  position: relative; }

.pane-news-panel-pane-5 strong,
.pane-news-panel-pane-6 strong,
.pane-news-panel-pane-7 strong,
.pane-news-panel-pane-8 strong {
  margin-top: 10px; }

.pane-news-panel-pane-5 .view-teaser,
.pane-news-panel-pane-6 .view-teaser,
.pane-news-panel-pane-7 .view-teaser,
.pane-news-panel-pane-8 .view-teaser {
  margin: 20px 0 0 0; }

.pane-news-panel-pane-5 .file,
.pane-news-panel-pane-6 .file,
.pane-news-panel-pane-7 .file,
.pane-news-panel-pane-8 .file {
  float: right; }
  @media screen and (min-width: 992px) {
    .pane-news-panel-pane-5 .file,
    .pane-news-panel-pane-6 .file,
    .pane-news-panel-pane-7 .file,
    .pane-news-panel-pane-8 .file {
      width: 91.36%; } }

.pane-news-panel-pane-5 .views-field-nothing,
.pane-news-panel-pane-6 .views-field-nothing,
.pane-news-panel-pane-7 .views-field-nothing,
.pane-news-panel-pane-8 .views-field-nothing {
  float: left;
  height: 67px;
  width: 53px;
  margin: 10px 20px 24px 0;
  background-color: #dddddd; }
  .pane-news-panel-pane-5 .views-field-nothing:after,
  .pane-news-panel-pane-6 .views-field-nothing:after,
  .pane-news-panel-pane-7 .views-field-nothing:after,
  .pane-news-panel-pane-8 .views-field-nothing:after {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    position: relative;
    top: -38px;
    left: 53px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #dddddd; }
  .pane-news-panel-pane-5 .views-field-nothing .dayno,
  .pane-news-panel-pane-6 .views-field-nothing .dayno,
  .pane-news-panel-pane-7 .views-field-nothing .dayno,
  .pane-news-panel-pane-8 .views-field-nothing .dayno {
    color: #555555;
    width: 100%;
    display: block;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-bottom: -10px; }
  .pane-news-panel-pane-5 .views-field-nothing .tlm,
  .pane-news-panel-pane-6 .views-field-nothing .tlm,
  .pane-news-panel-pane-7 .views-field-nothing .tlm,
  .pane-news-panel-pane-8 .views-field-nothing .tlm {
    color: #555555;
    width: 100%;
    display: block;
    text-align: center; }

/* Faculty Style */
.view-id-faculty_view.view-display-id-panel_pane_1 .view-content > div, .view-id-faculty_view.view-display-id-panel_pane_2 .view-content > div,
.view-id-faculty_view.view-display-id-panel_pane_3 .view-content > div, .view-id-faculty_view.view-display-id-panel_pane_4 .view-content > div,
.view-id-faculty_view.view-display-id-panel_pane_5 .view-content > div, .view-id-faculty_view.view-display-id-panel_pane_6 .view-content > div,
.view-id-faculty_view.view-display-id-panel_pane_7 .view-content > div, .view-id-faculty_view.view-display-id-panel_pane_8 .view-content > div,
.view-id-faculty_view.view-display-id-panel_pane_9 .view-content > div, .view-id-faculty_view.view-display-id-panel_pane_10 .view-content > div,
.view-id-faculty_view.view-display-id-panel_pane_11 .view-content > div {
  float: left;
  width: 100%;
  margin: 10px 0; }

.view-id-faculty_view.view-display-id-panel_pane_1 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_1 .views-field-field-faculty-external-bio-link, .view-id-faculty_view.view-display-id-panel_pane_2 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_2 .views-field-field-faculty-external-bio-link,
.view-id-faculty_view.view-display-id-panel_pane_3 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_3 .views-field-field-faculty-external-bio-link, .view-id-faculty_view.view-display-id-panel_pane_4 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_4 .views-field-field-faculty-external-bio-link,
.view-id-faculty_view.view-display-id-panel_pane_5 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_5 .views-field-field-faculty-external-bio-link, .view-id-faculty_view.view-display-id-panel_pane_6 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_6 .views-field-field-faculty-external-bio-link,
.view-id-faculty_view.view-display-id-panel_pane_7 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_7 .views-field-field-faculty-external-bio-link, .view-id-faculty_view.view-display-id-panel_pane_8 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_8 .views-field-field-faculty-external-bio-link,
.view-id-faculty_view.view-display-id-panel_pane_9 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_9 .views-field-field-faculty-external-bio-link, .view-id-faculty_view.view-display-id-panel_pane_10 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_10 .views-field-field-faculty-external-bio-link,
.view-id-faculty_view.view-display-id-panel_pane_11 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_11 .views-field-field-faculty-external-bio-link {
  display: block;
  float: left;
  margin-bottom: 20px; }

.view-id-faculty_view.view-display-id-panel_pane_1 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_2 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_3 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_4 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_5 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_6 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_7 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_8 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_9 .views-field-views-conditional, .view-id-faculty_view.view-display-id-panel_pane_10 .views-field-views-conditional,
.view-id-faculty_view.view-display-id-panel_pane_11 .views-field-views-conditional {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #000; }

.view-id-faculty_view.view-display-id-panel_pane_1 .views-field-field-faculty-name, .view-id-faculty_view.view-display-id-panel_pane_2 .views-field-field-faculty-name,
.view-id-faculty_view.view-display-id-panel_pane_3 .views-field-field-faculty-name, .view-id-faculty_view.view-display-id-panel_pane_4 .views-field-field-faculty-name,
.view-id-faculty_view.view-display-id-panel_pane_5 .views-field-field-faculty-name, .view-id-faculty_view.view-display-id-panel_pane_6 .views-field-field-faculty-name,
.view-id-faculty_view.view-display-id-panel_pane_7 .views-field-field-faculty-name, .view-id-faculty_view.view-display-id-panel_pane_8 .views-field-field-faculty-name,
.view-id-faculty_view.view-display-id-panel_pane_9 .views-field-field-faculty-name, .view-id-faculty_view.view-display-id-panel_pane_10 .views-field-field-faculty-name,
.view-id-faculty_view.view-display-id-panel_pane_11 .views-field-field-faculty-name {
  font-weight: bold; }

.view-id-faculty_view.view-display-id-panel_pane_1 .views-field-field-faculty-photo, .view-id-faculty_view.view-display-id-panel_pane_2 .views-field-field-faculty-photo,
.view-id-faculty_view.view-display-id-panel_pane_3 .views-field-field-faculty-photo, .view-id-faculty_view.view-display-id-panel_pane_4 .views-field-field-faculty-photo,
.view-id-faculty_view.view-display-id-panel_pane_5 .views-field-field-faculty-photo, .view-id-faculty_view.view-display-id-panel_pane_6 .views-field-field-faculty-photo,
.view-id-faculty_view.view-display-id-panel_pane_7 .views-field-field-faculty-photo, .view-id-faculty_view.view-display-id-panel_pane_8 .views-field-field-faculty-photo,
.view-id-faculty_view.view-display-id-panel_pane_9 .views-field-field-faculty-photo, .view-id-faculty_view.view-display-id-panel_pane_10 .views-field-field-faculty-photo,
.view-id-faculty_view.view-display-id-panel_pane_11 .views-field-field-faculty-photo {
  float: left;
  margin: 0 20px 0 0; }

.view-id-faculty_view.view-display-id-panel_pane_1 h3, .view-id-faculty_view.view-display-id-panel_pane_2 h3,
.view-id-faculty_view.view-display-id-panel_pane_3 h3, .view-id-faculty_view.view-display-id-panel_pane_4 h3,
.view-id-faculty_view.view-display-id-panel_pane_5 h3, .view-id-faculty_view.view-display-id-panel_pane_6 h3,
.view-id-faculty_view.view-display-id-panel_pane_7 h3, .view-id-faculty_view.view-display-id-panel_pane_8 h3,
.view-id-faculty_view.view-display-id-panel_pane_9 h3, .view-id-faculty_view.view-display-id-panel_pane_10 h3,
.view-id-faculty_view.view-display-id-panel_pane_11 h3 {
  margin: 20px 0 0;
  float: left;
  width: 100%; }

table.galtable {
  width: 100%; }
  table.galtable tbody {
    border-top: none; }
    table.galtable tbody tr td img {
      margin: 10px 0; }

.view-id-news.view-display-id-panel_pane_9 {
  margin-top: 20px; }
  .view-id-news.view-display-id-panel_pane_9 h3 {
    width: 100%;
    float: left;
    margin-bottom: 10px; }

.ctools-collapsible-container h2.pane-title {
  position: relative;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600;
  font-family: "open-sans", sans-serif; }
  .ctools-collapsible-container h2.pane-title:hover {
    background: #f7f7f7;
    color: #e87722; }
  .ctools-collapsible-container h2.pane-title:after {
    content: '-';
    color: #cf4520;
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid #ddd; }

.ctools-collapsible-content {
  padding: 0 50px 0 10px; }

.ctools-collapsed h2.pane-title {
  color: #cf4520; }
  .ctools-collapsed h2.pane-title:after {
    content: '+'; }

.ctools-collapsible-container .ctools-toggle-collapsed, .ctools-toggle {
  display: none; }

/* Carousel Style overrides */
.carousel-slide .overlay-text {
  max-width: 50%;
  text-align: left;
  left: 20px;
  top: 25%;
  opacity: 1; }
  @media screen and (min-width: 992px) {
    .carousel-slide .overlay-text {
      left: 34%;
      top: 50%; } }
  .carousel-slide .overlay-text h1 {
    color: #fff;
    opacity: 1; }
  .carousel-slide .overlay-text p {
    color: #fff;
    font-size: 18px;
    opacity: 1;
    font-family: "open-sans", sans-serif;
    margin-top: 20px;
    line-height: 1.5; }
  .carousel-slide .overlay-text a {
    opacity: 1;
    font-family: "open-sans", sans-serif;
    font-size: 13px; }

/*
.btn--wcm {
    border-radius: 15px;
    height: 42px;
    line-height: 31px;
    position: relative;
    display: block;
    width: 220px;
    margin: 0 auto;
    border-radius: 20px;
    border-width: 4px;
    font-size: 13px;
    color: #cf4520;
    background-color: transparent;
    border-color: #e87722;
}

.btn {
    padding-bottom: 1px;
    border-radius: 10px;
    padding: 6px 20px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.666666667;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.btn--wcm:before {
    left: 70%;
    opacity: 0;
    top: 0;
}
.btn--wcm:before {
    position: absolute;
    height: 100%;
    font-size: 125%;
    color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    content: '\e802';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 2.0;
}*/
.slide-text .container {
  display: table; }
  .slide-text .container p {
    display: table-cell;
    vertical-align: middle;
    float: none; }
  .slide-text .container h2 {
    display: table-cell;
    vertical-align: middle;
    float: none; }
    .slide-text .container h2 a {
      color: #e7751d; }
      .slide-text .container h2 a:hover {
        text-decoration: underline; }

.slide-image {
  max-height: 700px;
  overflow: hidden; }
  .slide-image a > img {
    width: 110%;
    max-width: 110%;
    height: 110%;
    max-height: 110%; }

@media screen and (min-width: 992px) {
  .footer-address {
    width: 22%; } }

.footer-address strong:not(:first-child) {
  padding-top: 5px;
  float: left;
  width: 100%; }

@media screen and (min-width: 992px) {
  .footer-nav {
    width: 78%; } }

div.caption img, div.caption object {
  margin-bottom: 10px; }

.view-id-faculty_spotlight.view-display-id-panel_pane_3 .views-field-title {
  width: 100%;
  border-top: 1px solid #555;
  margin-top: 20px;
  padding-top: 20px; }

/*.pane-node-body{
  float: left;
  width: 100%;
}*/
#second-level-nav ul {
  float: left;
  width: 100%; }

html.js .ctools-collapsible-container .ctools-collapsible-handle {
  display: block;
  float: left;
  width: 100%; }

#main-content {
  float: left; }

.information-column {
  float: left; }

.pane-bmri-home-callout {
  margin-bottom: 50px; }

.bmri-home-callout {
  background-color: #e7751d;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0; }
  @media screen and (min-width: 768px) {
    .bmri-home-callout {
      padding: 70px 0; } }
  .bmri-home-callout .callout-container {
    float: none;
    margin: 0 auto; }
  .bmri-home-callout .callout-text {
    color: #fff;
    margin-bottom: 40px;
    position: relative;
    padding-top: 15px; }
    @media screen and (min-width: 768px) {
      .bmri-home-callout .callout-text {
        padding-left: 40px; } }
    @media screen and (min-width: 992px) {
      .bmri-home-callout .callout-text {
        padding-right: 40%; } }
    .bmri-home-callout .callout-text p {
      font-size: 20px;
      font-family: "1898Sans-Regular", sans-serif; }
      @media screen and (min-width: 768px) {
        .bmri-home-callout .callout-text p {
          font-size: 30px; } }
    .bmri-home-callout .callout-text h1, .bmri-home-callout .callout-text h2, .bmri-home-callout .callout-text h3, .bmri-home-callout .callout-text h4 {
      font-size: 20px;
      color: #fff;
      font-family: "1898Sans-Regular", sans-serif;
      margin-top: 0;
      margin-bottom: 12px; }
    .bmri-home-callout .callout-text a {
      color: #fff;
      text-decoration: underline;
      font-size: 16px; }
      @media screen and (min-width: 768px) {
        .bmri-home-callout .callout-text a {
          font-size: 20px; } }
    @media screen and (min-width: 768px) {
      .bmri-home-callout .callout-text:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 2px solid #fff; } }
    @media screen and (min-width: 768px) {
      .bmri-home-callout .callout-text:after {
        content: '';
        position: absolute;
        top: 0;
        left: -4px;
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 10px; } }
  .bmri-home-callout .callout-btns .btn--wcm {
    color: #fff;
    width: auto;
    display: inline-block;
    border-color: #fff;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    height: auto;
    font-size: 16px;
    padding: 8px 50px;
    margin-right: 20px; }
    @media screen and (max-width: 767px) {
      .bmri-home-callout .callout-btns .btn--wcm {
        display: block;
        margin-bottom: 10px; } }

.page-faculty-research-publications {
  /*&.page-research-publications-posters {
        h1.title {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }*/ }
  .page-faculty-research-publications .site-main {
    /*@include breakpoint($sm) {
            width: 768px;
        }
        @include breakpoint($md) {
            width: 980px;
        }
        @include breakpoint($lg) {
            width: 1180px;
        }*/ }
    .page-faculty-research-publications .site-main #biblio-header a {
      padding-left: 10px;
      padding-right: 10px; }
      .page-faculty-research-publications .site-main #biblio-header a:first-of-type {
        padding-left: 0; }
    .page-faculty-research-publications .site-main #biblio-header img {
      margin-left: -10px;
      padding-right: 10px;
      vertical-align: middle; }

.view-events .views-exposed-form .views-exposed-widget {
  float: none; }
  .view-events .views-exposed-form .views-exposed-widget label {
    font-size: 13px; }

.view-events .views-row {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px; }
  .view-events .views-row:before, .view-events .views-row:after {
    content: " ";
    display: table; }
  .view-events .views-row:after {
    clear: both; }
  .view-events .views-row .views-field-field-event-date {
    color: #666666;
    font-family: "1898Sans-Bold", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 5px; }
  .view-events .views-row .views-field-field-event-image img {
    float: none;
    max-width: none;
    width: auto;
    margin-right: .5em;
    margin-bottom: .25em;
    margin-top: .25em; }
    @media screen and (min-width: 768px) {
      .view-events .views-row .views-field-field-event-image img {
        float: right;
        width: 25%;
        max-width: 25%;
        margin-left: 10px;
        margin-right: 0; } }

.node-type-event .pane-node-field-event-date {
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 5px; }

.pane-events-panel-pane-2 .views-row {
  border-bottom: none; }
  .pane-events-panel-pane-2 .views-row .views-field-field-event-link {
    margin-bottom: 10px; }
  .pane-events-panel-pane-2 .views-row .views-field-field-event-location {
    margin-bottom: 10px; }
  .pane-events-panel-pane-2 .views-row .views-field-field-event-image img {
    float: none;
    max-width: none;
    width: auto;
    margin-left: 0; }

.node-type-news-post.faculty-spotlight-news-post .news-featured-image {
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .node-type-news-post.faculty-spotlight-news-post .news-featured-image {
      float: left;
      margin-right: 20px; } }
  .node-type-news-post.faculty-spotlight-news-post .news-featured-image .hero-image img {
    width: auto; }

.node-type-news-post .post-date {
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 5px; }

.node-type-news-post .pane-node-field-source-link .field-label {
  float: left; }

.view-news .views-exposed-form .views-exposed-widget {
  float: none; }
  .view-news .views-exposed-form .views-exposed-widget label {
    font-size: 13px; }

.pane-news-panel-pane-1 .view-teaser {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px; }
  .pane-news-panel-pane-1 .view-teaser:before, .pane-news-panel-pane-1 .view-teaser:after {
    content: " ";
    display: table; }
  .pane-news-panel-pane-1 .view-teaser:after {
    clear: both; }
  .pane-news-panel-pane-1 .view-teaser .post-date {
    color: #666666;
    font-family: "1898Sans-Bold", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 5px; }
  .pane-news-panel-pane-1 .view-teaser .teaser-image img.panopoly-image-quarter {
    float: none;
    margin: 0 0 0.25em 0;
    width: auto;
    max-width: none; }
    @media screen and (min-width: 768px) {
      .pane-news-panel-pane-1 .view-teaser .teaser-image img.panopoly-image-quarter {
        float: right;
        margin: 0.25em 0 1.3em 1em;
        width: 25%;
        max-width: 25%; } }
