.brand__lockup .container a{
  img{
    max-height: 84px;
  }
}

#primary-nav .level-1 {
    width: 16.6667%;// 14.28%;
    @include breakpoint($sm){
      padding: 8px 4px;
      height: 64px;
    }
    @include breakpoint($lg){
      height: auto;
    }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

.block-menu-block .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
}

.pane-node-field-section-header-image img{
  width: 100%;
}

h2.cta_h2{
  margin-bottom: 10px;
}

.cta_img{
  float: left;
    img{
        margin: 0 10px 10px 0;
        
    }
}

.messages.error{
  display: none;
  
}

.pane-seminar-events-panel-pane-3, .pane-faculty-spotlight-panel-pane-2{
  h3{
    margin-bottom: 20px;
  }
}

.pane-node-field-news-category {
  clear: both;
}
.field-name-field-news-category, .views-field-field-news-category {
    a {
        color: $wcm-dark-orange;
        font-size: 14px;
        background: url(../images/tag.png) 0 0 no-repeat;
        background-size: 16px 16px;
        padding-left: 25px;
        position: relative;
    }
}

.pane-news-panel-pane-5, 
.pane-news-panel-pane-6, 
.pane-news-panel-pane-7, 
.pane-news-panel-pane-8{
  strong{
    margin-top: 10px;
  }
  .view-teaser{
    margin: 20px 0 0 0;
  }
  .file{
    @include breakpoint($md){
      width: 91.36%;
    }
    float: right;
  }
  .views-field-nothing{
    float: left;
    height: 67px;
    width: 53px;
    margin: 10px 20px 24px 0;
    background-color: #dddddd;
    &:after{
      display: inline-block;
      content: "";
      width: 0; 
      height: 0; 
      position: relative;
      top: -38px;
      left: 53px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid #dddddd;
    }
    .dayno{
      color: #555555;
      width: 100%;
      display: block;
      font-size:26px;
      font-weight:700;
      text-align: center;
      //margin-top: 8px;
      margin-bottom:-10px;
    }
    .tlm{
      color:  #555555;
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}

/* Faculty Style */
.view-id-faculty_view.view-display-id-panel_pane_1, .view-id-faculty_view.view-display-id-panel_pane_2,
.view-id-faculty_view.view-display-id-panel_pane_3, .view-id-faculty_view.view-display-id-panel_pane_4,
.view-id-faculty_view.view-display-id-panel_pane_5, .view-id-faculty_view.view-display-id-panel_pane_6,
.view-id-faculty_view.view-display-id-panel_pane_7, .view-id-faculty_view.view-display-id-panel_pane_8,
.view-id-faculty_view.view-display-id-panel_pane_9, .view-id-faculty_view.view-display-id-panel_pane_10, 
.view-id-faculty_view.view-display-id-panel_pane_11{
  .view-content{
    >div{
      float: left;
      width: 100%;
      margin: 10px 0;
    }
  }
  .views-field-views-conditional, .views-field-field-faculty-external-bio-link{
    display: block;
    float: left;
    margin-bottom: 20px;
  }
  .views-field-views-conditional{
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #000;
  }
  .views-field-field-faculty-name{
    font-weight: bold;
  }
  .views-field-field-faculty-photo{
    float: left;
    margin: 0 20px 0 0;
  }
  h3{
    margin: 20px 0 0;
    float: left;
    width: 100%;
  }
}

table.galtable{
  width: 100%;
  tbody{
    border-top: none;
    tr{
      td{
        img{
          margin: 10px 0;
        }
      }
    }
  }
}

.view-id-news.view-display-id-panel_pane_9{
  margin-top: 20px;
  h3{
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
}

.ctools-collapsible-container{
  h2.pane-title{
    position: relative;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    border: 1px solid #ddd;
    border-width: 1px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 55px 15px 10px;
    font-weight: 600;
    font-family: "open-sans", sans-serif;
    &:hover{
      background: #f7f7f7;
      color: #e87722;
    }
    &:after{
      content: '-';
      color: #cf4520;
      width: 50px;
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      border-left: 1px solid #ddd;
    }
  }
}

.ctools-collapsible-content{
  padding: 0 50px 0 10px;
}

.ctools-collapsed h2.pane-title{
  color: #cf4520;
  &:after{
      content: '+';
  }
}

.ctools-collapsible-container .ctools-toggle-collapsed, .ctools-toggle{
  display: none;
}

/* Carousel Style overrides */

.carousel-slide{
  .overlay-text{
    max-width: 50%;
    text-align: left;
    left: 20px;
    top: 25%;
    opacity: 1;
    @include breakpoint($md){
      left: 34%;
      top: 50%;
    }
     h1{
      color: #fff;
      opacity: 1;
     }
     p{
      color: #fff;
      font-size: 18px;
      opacity: 1;
      font-family: "open-sans", sans-serif;
      margin-top : 20px;
      line-height: 1.5;
     }
     a{
      opacity: 1;
      font-family: "open-sans", sans-serif;
      font-size: 13px;

  }
 }
  

}


/*
.btn--wcm {
    border-radius: 15px;
    height: 42px;
    line-height: 31px;
    position: relative;
    display: block;
    width: 220px;
    margin: 0 auto;
    border-radius: 20px;
    border-width: 4px;
    font-size: 13px;
    color: #cf4520;
    background-color: transparent;
    border-color: #e87722;
}

.btn {
    padding-bottom: 1px;
    border-radius: 10px;
    padding: 6px 20px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.666666667;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.btn--wcm:before {
    left: 70%;
    opacity: 0;
    top: 0;
}
.btn--wcm:before {
    position: absolute;
    height: 100%;
    font-size: 125%;
    color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    content: '\e802';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 2.0;
}*/

.slide-text .container{
  display: table; 
  //position: absolute; 
  p{
    display: table-cell; 
    vertical-align: middle;
    float: none;
  }
  h2{
    display: table-cell; 
    vertical-align: middle;
    float: none;
    a{
      color: $wcm-bright-orange;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}


.slide-image{
  max-height: 700px;
  overflow: hidden;
  a>img {
    width: 110%;
    max-width: 110%;
    height: 110%;
    max-height: 110%;
  }
}

.footer-address{
  @include breakpoint($md){
    width: 22%;
  }
  strong:not(:first-child){
    padding-top: 5px;
    float: left;
    width: 100%;
  }
}

.footer-nav{
  @include breakpoint($md){
    width: 78%;
  }
}

div.caption img, div.caption object {
    margin-bottom: 10px;
}

.view-id-faculty_spotlight.view-display-id-panel_pane_3 {
  .views-field-title{
    width: 100%;
    border-top: 1px solid #555;
    margin-top: 20px;
    padding-top: 20px;
  }
}

/*.pane-node-body{
  float: left;
  width: 100%;
}*/

#second-level-nav ul{
  float: left;
  width: 100%;
}

html.js .ctools-collapsible-container .ctools-collapsible-handle {
    display: block;
    float: left;
    width: 100%;
}

#main-content{
  float: left;
}

.information-column{
  float: left;
}

.pane-bmri-home-callout {
  margin-bottom:50px;
}
.bmri-home-callout {
  background-color: $wcm-bright-orange;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;
  @include breakpoint($sm) {
    padding: 70px 0;
  }

  .callout-container {
    float: none;
    margin: 0 auto;
  }

  .callout-text {
    color: $wcm-white;
    margin-bottom: 40px;
    position: relative;
    @include breakpoint($sm) {
      padding-left: 40px;
    }
    @include breakpoint($md) {
      padding-right: 40%;
    }
    padding-top: 15px;

    p {
      font-size: 20px;
      @include breakpoint($sm) {
        font-size: 30px;
      }
      font-family: $wcm-regular;
    }

    h1, h2, h3, h4 {
      font-size: 20px;
      color: $wcm-white;
      font-family: $wcm-regular;
      margin-top: 0;
      margin-bottom: 12px;
    }

    a {
      color: $wcm-white;
      text-decoration: underline;
      font-size: 16px;
      @include breakpoint($sm) {
        font-size: 20px;
      }
    }

    &:before {
      @include breakpoint($sm) {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-left: 2px solid $wcm-white;
      }
    }

    &:after {
      @include breakpoint($sm) {
        content: '';
        position: absolute;
        top: 0;
        left: -4px;
        width: 10px;
        height: 10px;
        background: $wcm-white;
        border-radius: 10px;
      }
    }
  }
  .callout-btns {
    .btn--wcm {
      color: $wcm-white;
      width: auto;
      display: inline-block;
      border-color: $wcm-white;
      -webkit-border-radius: 20px;
      border-radius: 20px;
      height: auto;
      font-size: 16px;
      padding: 8px 50px;
      margin-right: 20px;

      @include breakpoint($xs) {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

.page-faculty-research-publications {

    /*&.page-research-publications-posters {
        h1.title {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }*/

    .site-main {

        #biblio-header {
            a {
                padding-left: 10px;
                padding-right: 10px;
                &:first-of-type {
                    padding-left: 0;
                }
            }
            img {
                margin-left: -10px;
                padding-right: 10px;
                vertical-align: middle;
            }
        }

        /*@include breakpoint($sm) {
            width: 768px;
        }
        @include breakpoint($md) {
            width: 980px;
        }
        @include breakpoint($lg) {
            width: 1180px;
        }*/
    }
}

.view-events {

  .views-exposed-form .views-exposed-widget {
    float: none;

    label {
      font-size: 13px;
    }
  }

  .views-row {
    @include clearfix();
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;

    .views-field-field-event-date {
      color: $wcm-med-gray;
      font-family: $wcm-bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 13px;
      margin-top: 5px;
    }

    .views-field-field-event-image {
      img {
        float: none;
        max-width: none;
        width: auto;
        margin-right: .5em;
        margin-bottom: .25em;
        margin-top: .25em;

        @include breakpoint($sm) {
          float: right;
          width: 25%;
          max-width: 25%;
          margin-left: 10px;
          margin-right: 0;
        } 
      }
    }
  }
}

.node-type-event {
  .pane-node-field-event-date {
    color: $wcm-med-gray;
      font-family: $wcm-bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 13px;
      margin-top: 5px;
  }
}

.pane-events-panel-pane-2 {
  .views-row {
    border-bottom: none;

    .views-field-field-event-link {
      margin-bottom: 10px;
    }
    .views-field-field-event-location {
      margin-bottom: 10px;
    }

    .views-field-field-event-image {
      img {
        float: none;
        max-width: none;
        width: auto;
        margin-left: 0;
        // margin-right: .5em;
        // margin-bottom: .25em;
        // margin-top: .25em;
      }
    }
  }
}

.node-type-news-post {

  &.faculty-spotlight-news-post {
    .news-featured-image {
      margin-bottom: 20px;
      @include breakpoint($sm) {
        float: left;
        margin-right: 20px;
      }
      .hero-image {
        img {
          width: auto;
        }
      }
    }
  }

  .post-date {
    color: $wcm-med-gray;
    font-family: $wcm-bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 5px;
  }

  .pane-node-field-source-link {
    .field-label {
      float: left;
    }
  }
}

.view-news {

  .views-exposed-form .views-exposed-widget {
    float: none;

    label {
      font-size: 13px;
    }
  }
}

.pane-news-panel-pane-1 {
  .view-teaser {
    @include clearfix();
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;

    .post-date {
      color: $wcm-med-gray;
      font-family: $wcm-bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 13px;
      margin-top: 5px;
    }

    .teaser-image img.panopoly-image-quarter {
        float: none;
        margin: 0 0 0.25em 0;
        width: auto;
        max-width: none;
        @include breakpoint($sm) {
            float: right;
            margin: 0.25em 0 1.3em 1em;
            width: 25%;
            max-width: 25%;
        }
    }
  }
}